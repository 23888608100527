import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface ApiRequestOptions {
	url: string;
	method: AxiosRequestConfig['method'];
	body?: any;
}

export const apiRequest = async (options: ApiRequestOptions): Promise<AxiosResponse<any>> => {
    const url = `${API_BASE_URL}${options.url}`;

    const requestOptions: AxiosRequestConfig = {
        method: options.method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    };

    if (options.body) {
        requestOptions.data = JSON.stringify(options.body);
    }

    try {
        const response = await axios(url, requestOptions);
        return response; // Ensure a value is returned in this code path
    } catch (error: any) {
        if (error.response && error.response.status === 401) {
            console.error("Unauthenticated error, redirecting to login.");
            localStorage.removeItem('token');
            window.location.href = '/login';
        }
        throw error;
    }
};
