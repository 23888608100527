import React from 'react';
import { Route, Routes } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { userRoutes, moderateRoutes, adminRoutes, guestRoutes , customerManagerRoutes} from '../../routes/contentRoutes';
import Spinner from '../../components/bootstrap/Spinner';
import { customerManagerPagesMenu } from '../../menu';

const PAGE_404 = React.lazy(() => import('../../pages/presentation/auth/Page404'));

const ContentRoutes = () => {
    const { userData, loading } = useAuth();
    let selectedRoutes = [];

    if (loading) {
        return <div className='d-flex justify-content-center align-items-center h-100 w-100'>
		<Spinner />
	</div>
    }
    //console.log('userData', userData.user_group_id);
    if (userData?.user_group_id === 3) { // User
        selectedRoutes = userRoutes;
    } else if (userData?.user_group_id === 2) { // Moderatör
        selectedRoutes = moderateRoutes;
    } else if (userData?.user_group_id === 1) { // Admin
        selectedRoutes = [...adminRoutes, ...moderateRoutes, ...userRoutes];
    } else if (userData?.user_group_id === 4) { // Müşteri Yöneticisi
        selectedRoutes = [ ...customerManagerRoutes, ...userRoutes];
    } 
     else {
        selectedRoutes = guestRoutes;
    }

    return (
        <Routes>
            {selectedRoutes.map((page) => (
                <Route key={page.path} {...page} />
            ))}
            <Route path="*" element={<PAGE_404 />} />
        </Routes>
    );
};

export default ContentRoutes;
