import React, { lazy } from 'react';
import { RouteProps, useParams } from 'react-router-dom';
import {
	authMenu,
	createOrderMenu,
	dashboardPagesMenu,
	userPagesMenu,
	adminPagesMenu,
	moderatorPagesMenu,
	landingPagesMenu,
} from '../menu';
import Login from '../pages/presentation/auth/Login';
import AutoLogin from '../pages/presentation/auth/AutoLogin';
import AcceptShipmentPage from '../pages/presentation/adminpages/acceptshipment/acceptshipment';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};
const CREATEORDERMENU = {
	CREATEORDER: lazy(() => import('../pages/presentation/siparisler/CreateShipment')),
};
const LIST = {
	ORDERS: lazy(() => import('../pages/presentation/siparisler/tumsiparisler')),
};
const PRICING = {
	PRICING_TABLE: lazy(() => import('../pages/presentation/pricing/FiyatHesapla')),
};
const BALANCE = {
	BALANCE_ADD: lazy(() => import('../pages/presentation/balance/BalanceAddPage')),
	BALANCE_FAIL: lazy(() => import('../pages/presentation/balance/BalanceFailure')),
	BALANCE_SUCCESS: lazy(() => import('../pages/presentation/balance/BalanceSuccess')),
	BALANCE_LIST: lazy(() => import('../pages/presentation/balance/PaymentHistory')),
};
const PRICEDEFINATION = {
	PRICEDEFINATION_PAGE: lazy(() => import('../pages/adminPages/price-defination/PriceDefinationPage')),
	PRICEDEFINATION_DETAIL_PAGE: lazy(() => import('../pages/adminPages/price-defination/PriceDefinationDetailPage')),
	PRICEDEFINATION_COUNTRY_PAGE: lazy(() => import('../pages/adminPages/price-defination/PriceDefinationCountryPage')),
};

const CARRIERDEFINATION = {
	CARRIERDEFINATION_PAGE: lazy(() => import('../pages/adminPages/carrier-defination/CarrierDefinationPage')),
	CARRIERDEFINATION_DETAIL_PAGE: lazy(() => import('../pages/adminPages/carrier-defination/CarrierDefinationDetailPage')),
};
const USER = {
	USER_LIST: lazy(() => import('../pages/adminPages/user/UserListPage')),
	USER_DETAIL: lazy(() => import('../pages/adminPages/user/UserDetailPage')),
	USER_BALANCE: lazy(() => import('../pages/adminPages/user/balance/AddBalanceEftPage')),
};

const USERBALANCES = {
	USERBALANCES_LIST: lazy(() => import('../pages/adminPages/user-balances/UserBalancesPage')),
};

const SENDMAILUSERS = {
	SENDMAILUSERS_SEND: lazy(() => import('../pages/adminPages/send-mail-users/SendMailUsersPage')),
};

const COUNTRYZONES = {
	COUNTRYZONES_LIST: lazy(() => import('../pages/adminPages/country-zones/CountryZoneListPage')),
};
const ADMIN = {
	ACCEPTSHIPMENT: lazy(() => import('../pages/presentation/adminpages/acceptshipment/acceptshipment')),
	ANNOUNCEMENTS: lazy(() => import('../pages/presentation/adminpages/announcements'))
}
const AUTH = {
	FORGOT_PASSWORD: lazy(() => import('../pages/presentation/auth/ForgotPassword')),
	RESET_PASSWORD: lazy(() => import('../pages/presentation/auth/ResetPassword')),
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const BARCODE = {
	BARDCODE: lazy(() => import('../pages/presentation/siparisler/GetBarcode')),
	INVOİCE : lazy(() => import('../pages/presentation/siparisler/GetInvoice')),
	DOWNLOAD : lazy(() => import('../pages/presentation/siparisler/DownloadBarcode')),
}

const PROFILE = {
	PROFILE_PAGE: lazy(() => import('../pages/presentation/profile/ProfilePage')),
}
const APP = {
	PROJECT_MANAGEMENT: {},
	APPOINTMENT: {
		EMPLOYEE_VIEW: lazy(() => import('../pages/presentation/appointment/EmployeePage')),
	},
	CHAT: {
		WITH_LIST: lazy(() => import('../pages/presentation/chat/WithListChatPage')),
	},
};
const PAGE_LAYOUTS = {
	HEADER: lazy(() => import('../pages/presentation/siparisler/CreateShipment')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const ETGBFORMS = {
	ETGBFORMS_PAGE: lazy(() => import('../pages/presentation/etgb-forms/EtgbFormsPage')),
}

const INTEGRATIONS = {
	INTEGRATIONS_PAGE: lazy(() => import('../pages/presentation/integrations/IntegrationsPage')),
	INTEGRATIONS_LIST_PAGE: lazy(() => import('../pages/presentation/integrations/IntegrationsListPage')),
	INTEGRATIONS_COMPLATED : lazy(() => import('../pages/presentation/integrations/complated')),
}

const COURIERS = {
	COURIERS_CREATE_PAGE: lazy(() => import('../pages/presentation/couriers/CreateCourierPage')),
	COURIERS_LIST_PAGE : lazy(() => import('../pages/presentation/couriers/ListCourierPage')),
}

const B2BOFFERS = {
	B2BOFFERS_CREATE_PAGE: lazy(() => import('../pages/presentation/b2b/CreateB2bOfferPage')),
	B2BOFFERS_LIST_PAGE : lazy(() => import('../pages/presentation/b2b/ListB2bOfferPage')),
}
const CLUB = {
	CLUB_PAGE: lazy(() => import('../pages/presentation/club/clubListPage')),
}

const FILEVIEWER = {
	FILEVIEWER_PAGE: lazy(() => import('../pages/presentation/helpers/FileViewer')),
}

const adminRoute: RouteProps[] = [
	{
		path: adminPagesMenu.carrierDefination.path,
		element: <CARRIERDEFINATION.CARRIERDEFINATION_PAGE />,
	},
	{
		path: adminPagesMenu.carrierDefination.path + '/:id',
		element: <CARRIERDEFINATION.CARRIERDEFINATION_DETAIL_PAGE />,
	}, {
		path: adminPagesMenu.priceDefination.path,
		element: <PRICEDEFINATION.PRICEDEFINATION_PAGE />,
	},
	{
		path: adminPagesMenu.priceDefination.path + '/:id',
		element: <PRICEDEFINATION.PRICEDEFINATION_DETAIL_PAGE />,
	},
	{
		path: adminPagesMenu.priceDefination.path + '-country/:id',
		element: <PRICEDEFINATION.PRICEDEFINATION_COUNTRY_PAGE />,
	},
	{
		path: adminPagesMenu.userList.path,
		element: <USER.USER_LIST />,
	},
	{
		path: adminPagesMenu.userList.path + '/:id',
		element: <USER.USER_DETAIL />,
	},
	{
		path: adminPagesMenu.addUserBalanceWithEft.path,
		element: <USER.USER_BALANCE />,
	},
	{
		path: adminPagesMenu.CountryZoneList.path,
		element: <COUNTRYZONES.COUNTRYZONES_LIST />,
	},
	{
		path: adminPagesMenu.announcement.path,
		element: <ADMIN.ANNOUNCEMENTS />,
	},
	{
		path: adminPagesMenu.userBalances.path,
		element: <USERBALANCES.USERBALANCES_LIST />,
	},
	{
		path: adminPagesMenu.sendMailUsers.path,
		element: <SENDMAILUSERS.SENDMAILUSERS_SEND />,
	},
];

const customerManagerRoute: RouteProps[] = [
	{
		path: adminPagesMenu.userList.path,
		element: <USER.USER_LIST />,
	},
	{
		path: adminPagesMenu.userList.path + '/:id',
		element: <USER.USER_DETAIL />,
	},
	{
		path: adminPagesMenu.userBalances.path,
		element: <USERBALANCES.USERBALANCES_LIST />,
	},
];


const userRoute: RouteProps[] = [
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: createOrderMenu.createOrder.path,
		element: <CREATEORDERMENU.CREATEORDER />,
	},
	{
		path: userPagesMenu.pricingTable.path,
		element: <PRICING.PRICING_TABLE />,
	},
	{
		path: userPagesMenu.balancePage.path,
		element: <BALANCE.BALANCE_ADD />,
	},
	{
		path: landingPagesMenu.balanceFailure.path,
		element: <BALANCE.BALANCE_FAIL />,
	},
	{
		path: landingPagesMenu.balanceSuccess.path,
		element: <BALANCE.BALANCE_SUCCESS />,
	},
	{
		path: landingPagesMenu.balanceList.path,
		element: <BALANCE.BALANCE_LIST />,
	},
	{
		path: authMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: authMenu.login.path,
		element: <Login />,
	},
	{
		path: authMenu.signUp.path,
		element: <Login isSignUp />,
	},
	{
		path: authMenu.autoLogin.path,
		element: <AutoLogin />,
	},
	{
		path: userPagesMenu.listPages.subMenu.listBoxed.path,
		element: <LIST.ORDERS />,
	},
	{
		path: userPagesMenu.listPages.subMenu.listBoxed.path + '/:id',
		element: <LIST.ORDERS />,
	},
	{
		path: landingPagesMenu.barcode.path,
		element: <BARCODE.BARDCODE />,
	},
	{
		path : landingPagesMenu.invoice.path,
		element : <BARCODE.INVOİCE />
	},
	{
		path : landingPagesMenu.downloadBarcode.path,
		element : <BARCODE.DOWNLOAD />
	},
	{
		path: userPagesMenu.support.path,
		element: <APP.CHAT.WITH_LIST />,
	},
	{
		path: authMenu.forgotPassword.path,
		element: <AUTH.FORGOT_PASSWORD />,
	},
	{
		path: authMenu.resetPassword.path,
		element: <AUTH.RESET_PASSWORD />,
	},
	{
		path: 'profile/:id',
		element: <PROFILE.PROFILE_PAGE />,
	},
	{
		path: userPagesMenu.etgbForms.path,
		element: <ETGBFORMS.ETGBFORMS_PAGE />,
	},
	{
		path: userPagesMenu.integrationsPage.path,
		element: <INTEGRATIONS.INTEGRATIONS_PAGE />,
	},
	{
		path: landingPagesMenu.integrationComplated.path,
		element: <INTEGRATIONS.INTEGRATIONS_COMPLATED />,
	},
	{
		path: landingPagesMenu.integrationsListPage.path,
		element: <INTEGRATIONS.INTEGRATIONS_LIST_PAGE />,
	},
	{
		path: userPagesMenu.courierList.path,
		element: <COURIERS.COURIERS_LIST_PAGE />,
	},
	{
		path: landingPagesMenu.courierCreate.path,
		element: <COURIERS.COURIERS_CREATE_PAGE />,
	},
	{
		path: userPagesMenu.b2bList.path,
		element: <B2BOFFERS.B2BOFFERS_LIST_PAGE />,
	},
	{
		path: landingPagesMenu.b2bCreate.path,
		element: <B2BOFFERS.B2BOFFERS_CREATE_PAGE />,
	},	
	{
		path : dashboardPagesMenu.lojistigoClub.path,
		element : <CLUB.CLUB_PAGE />,
	},
	{
		path : landingPagesMenu.fileViewer.path,
		element : <FILEVIEWER.FILEVIEWER_PAGE />,}
];
const guestRoute: RouteProps[] = [
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: authMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: authMenu.login.path,
		element: <Login />,
	},
	{
		path: authMenu.autoLogin.path,
		element: <AutoLogin />,
	},
	{
		path: authMenu.forgotPassword.path,
		element: <AUTH.FORGOT_PASSWORD />,
	},
	{
		path: authMenu.resetPassword.path,
		element: <AUTH.RESET_PASSWORD />,
	},
	{
		path: authMenu.signUp.path,
		element: <Login isSignUp />,
	},
];

const moderateRoute: RouteProps[] = [
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: moderatorPagesMenu.acceptshipment.path,
		element: <ADMIN.ACCEPTSHIPMENT />,
	},
	{
		path: moderatorPagesMenu.earlyTraceNumbers.path + '/:id',
		element: <LIST.ORDERS />,
	},
];

const userRoutes = [...userRoute];
const moderateRoutes = [...moderateRoute , ...userRoute];

const adminRoutes = [...adminRoute];
const guestRoutes = [...guestRoute]
const customerManagerRoutes = [...customerManagerRoute];

export { userRoutes, moderateRoutes, adminRoutes, guestRoutes , customerManagerRoutes };
