import React from 'react';
import { RouteProps } from 'react-router-dom';
import { authMenu, landingPagesMenu} from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
	{ path: authMenu.login.path, element: null },
	{ path: authMenu.signUp.path, element: null },
	{ path: authMenu.forgotPassword.path, element: null },
	{ path: authMenu.resetPassword.path, element: null },
	{ path: authMenu.autoLogin.path, element: null },
	{ path: landingPagesMenu.barcode.path, element: null },
	{ path: landingPagesMenu.invoice.path, element: null },
	{ path: landingPagesMenu.downloadBarcode.path, element: null },
	{ path: landingPagesMenu.balanceFailure.path, element: null },
	{ path: landingPagesMenu.balanceSuccess.path, element: null },
	{ path: landingPagesMenu.fileViewer.path, element: null },

	{ path: '*', element: <DefaultAside /> },
];

export default asides;
